import * as React from 'react';
import sanityClient from '@sanity/client'
import bcrypt from 'bcryptjs'
import {makeStyles} from '@material-ui/core/styles';
import {
  Box,
  Button,
  FormControl,
  TextField,
  CircularProgress,
  Typography,
  Link,
} from '@material-ui/core';
import {notNilOrEmpty} from '../lib/Helpers';
import cryptojs from "crypto-js";
import Swal from 'sweetalert2';
import { useAuth } from '../context/auth';

const client = sanityClient({
  projectId: process.env.GATSBY_SANITY_ID,
  dataset: process.env.GATSBY_SANITY_DATASET,
  token: process.env.GATSBY_SANITY_TOKEN,
  useCdn: false // `false` if you want to ensure fresh data
})

const useStyles = makeStyles (theme => ({
  button: {
    marginTop: theme.spacing (1),
    marginRight: theme.spacing (1),
  },
  actionsContainer: {
    width: '100%',
    marginBottom: theme.spacing (2),
    '& button': {
      width: '100%',
    },
  },
  form: {
    margin: '0 auto',
    maxWidth: '20rem',
    '& .form-input': {
      width: '80%!important',
    },
  },
  input: {
    marginBottom: '1.5rem',
    width: '100%',
  },
  formContainer: {
    width: '30%',
    margin: 'auto'
  }
}));

const loadUserData = async (key) => {
  const decryptedObject = cryptojs.AES.decrypt(key, process.env.GATSBY_RESET_SECRET);
  const userId = decryptedObject.toString(cryptojs.enc.Utf8);
  const user = await client
    .getDocument(userId)
    .catch(reason => (null));
  if (!user) {
    Swal.fire({
      title: 'Error',
      text: 'The password reset link is invalid, please contact support!',
      icon: 'error',
      showConfirmButton: false,
      confirmButtonText: '',
      showCloseButton: true,
      heightAuto: false,
      padding: '3em',
      customClass: {
        popup: 'popup',
        confirmButton: 'btn btn--black btn-confirm'
      },
      onClose: () => {
        if (typeof window !== 'undefined') {
          window.location.href = "/";
        }
      }
    })
    return;
  }
  return user;
}

export default () => {
  const classes = useStyles ();
  const { setCurrentUser, session } = useAuth()
  const [hasActiveSession, setHasActiveSession] = React.useState(false);
  const {search} = (typeof window !== 'undefined') ? window.location : {search: ''};

  if (!search.match(/\?key\=(.*)/)) {
    return (
      <div className="container">
        <div className={`animated fadeIn section-content`}>
          The requested url is invalid. <Link href="/">Click here</Link> to go back.
        </div>
      </div>
    )
  }

  const [user, setUser] = React.useState(null);
  const [isLoading, setIsLoadingState] = React.useState (false);
  const [formData, setFormData] = React.useState ({
    password: '',
    passwordConfirm: '',
  });
  const [blurredFields, setBlurredField] = React.useState ([]);

  React.useEffect(() => {
    if (session != null && session.user != null) {
      setHasActiveSession(true);
    }
  }, [session, setHasActiveSession]);

  React.useEffect(() => {
    loadUserData(search.replace('?key=', '')).then((userRecord) => {
      setUser(userRecord);
    }).catch((error) => {
      Swal.fire({
        title: 'Error',
        text: 'The password reset link is invalid, please contact support!',
        icon: 'error',
        showConfirmButton: false,
        confirmButtonText: '',
        showCloseButton: true,
        heightAuto: false,
        padding: '3em',
        customClass: {
          popup: 'popup',
          confirmButton: 'btn btn--black btn-confirm'
        },
        onClose: () => {
          if (typeof window !== 'undefined') {
            window.location.href = "/";
          }
        }
      });
    });
  }, [])

  function _handleInputChange (e) {
    e.preventDefault ();
    setFormData ({
      ...formData,
      [e.target.name]: e.target.value,
    });
  }

  const passwordsMatch = formData.passwordConfirm === formData.password;

  const shouldShowPasswordError =
    formData.passwordConfirm.length > 0 &&
    formData.password.length > 0 &&
    blurredFields.includes ('password') &&
    blurredFields.includes ('passwordConfirm') &&
    !passwordsMatch;

  const onSubmit = async () => {
    try {
      setIsLoadingState(true);
      const result = await client.patch(user._id).set({ password: bcrypt.hashSync(formData.password, 10)}).commit();
      if (result) {
        Swal.fire({
          title: 'Password update successful',
          text: 'Your password reset has been completed, you can go ahead and login.',
          icon: 'success',
          showConfirmButton: false,
          confirmButtonText: 'Send',
          showCloseButton: true,
          heightAuto: false,
          padding: '3em',
          customClass: {
            popup: 'popup',
            confirmButton: 'btn btn--black btn-confirm'
          },
          onClose: () => {
            if (typeof window !== 'undefined') {
              window.location.href = "/";
            }
          }
        })
      }
      setIsLoadingState(false);
    } catch(error) {
      Swal.fire({
        title: 'Password update failed',
        text: 'Please try again or contact support!',
        icon: 'error',
        showConfirmButton: false,
        confirmButtonText: '',
        showCloseButton: true,
        heightAuto: false,
        padding: '3em',
        customClass: {
          popup: 'popup',
          confirmButton: 'btn btn--black btn-confirm'
        }
      })
      setIsLoadingState(false);
    }
  }

  if (hasActiveSession) {
    return (
      <div className="container">
        <div className={`animated fadeIn section-content`}>
          An active session was detected, you need to <Link onClick={() => {
            setCurrentUser(null);
            if (typeof window !== 'undefined') {
              window.location.reload();
            }
          }}>sign out</Link> first.
        </div>
      </div>
    )
  }

  return (
    <div className="container">
      <div className={`animated fadeIn section-content`}>
        <div className="form-container">
            <Box
              display="flex"
              p={1}
              flexDirection="column"
              alignItems="center"
              className={classes.formContainer}
            >
              <Typography variant="h5" gutterBottom>
                Set New Password
              </Typography>
              <FormControl variant="outlined" className="form-input">
                <TextField
                  type="password"
                  label="Password"
                  variant="outlined"
                  color="secondary"
                  name="password"
                  onChange={_handleInputChange}
                  value={formData.password}
                  size="small"
                  classes={{
                    root: classes.input,
                  }}
                  onBlur={() =>
                    !blurredFields.includes ('password') &&
                    setBlurredField ([...blurredFields, 'password'])}
                />
              </FormControl>
              <FormControl variant="outlined" className="form-input">
                <TextField
                  type="password"
                  label="Confirm Password"
                  variant="outlined"
                  color="secondary"
                  name="passwordConfirm"
                  onChange={_handleInputChange}
                  value={formData.passwordConfirm}
                  size="small"
                  classes={{
                    root: classes.input,
                  }}
                  onBlur={() =>
                    !blurredFields.includes ('passwordConfirm') &&
                    setBlurredField ([...blurredFields, 'passwordConfirm'])}
                  {...{
                    error: shouldShowPasswordError,
                    helperText: shouldShowPasswordError
                      ? `The provided passwords don't match`
                      : '',
                  }}
                />
              </FormControl>
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyItems="space-between"
                className={classes.actionsContainer}
              >
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={() => onSubmit(formData)}
                  className={classes.button}
                  disabled={
                    !(notNilOrEmpty (formData.password) &&
                      notNilOrEmpty (formData.passwordConfirm) &&
                      formData.password === formData.passwordConfirm)
                  }
                >
                  {isLoading ? <CircularProgress /> : 'Submit'}
                </Button>
                </Box>
            </Box>
        </div>
      </div>
    </div>
  );
};
