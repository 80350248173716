import React from 'react'
import sanityClient from '@sanity/client'

import Hero from '../components/Hero'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import SetNewPasswordForm from '../components/SetNewPasswordForm'

export default () => {
  return (
    <Layout>
      <SEO
        title="Reset Password"
      />
      <Hero />
      <SetNewPasswordForm/>
    </Layout>
  )
}
